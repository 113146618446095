.App {
  text-align: center;
}
.container-buttons{
padding:0 50px 30px 0;
}

.option-section{
  max-height: 300px;
  overflow-y: auto;
}

.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

td .btn-outline-info{
  min-width: 40px;
  max-width: auto;
  max-height: 30px;
}

td  .btn-outline-danger{
  min-width: 40px;
  max-width: auto;
  max-height: 30px;
}


.td .btn-outline-info{
  min-width: 60px;
  max-width: auto;
  max-height: 30px;
}

.td  .btn-outline-danger{
  min-width: 60px;
  max-width: auto;
  max-height: 30px;
}
td .btn-outline-primary{
  max-height: 30px;
}
.container-buttons .btn-info{
  min-width: 60px;
  max-width: auto;
}

/* Custom styles for font sizes in ReactQuill toolbar */

/* Default style for font size options in the dropdown */
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value); /* Show the font size value */
  font-size: 14px; /* Default font size for dropdown items */
}

/* Style for the dropdown's selected value (displayed at the top of the dropdown) */
.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: attr(data-value); /* Display the selected value */
  font-size:16px; /* Size for the displayed selected value, adjust as needed */
}




.cardHome {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
}

.cardHome:first-child {
  background-color: #2962ff;
}

.cardHome:nth-child(2) {
  background-color: #ff6d00;
}

.cardHome:nth-child(3) {
  background-color: #2e7d32;
}

.cardHome:nth-child(4) {
  background-color: #d50000;
}

.cardHome-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardHome-inner > .card_icon {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  height: 300px;
}

/* End Main  */


/* Medium <= 992px */
  
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

 

  .menu-icon {
    display: inline;
  }


}
/* Small <= 768px */
  
@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  td .btn-outline-primary{
    max-height:50px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}


.alert-message{
 color: red;
 padding-left: 0px;
 margin-top: 5px;
}





/* Googlefont Roboto CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.container-buttons1 {
  position: fixed;
 width: 200px;
  top: 20%;
  right: 50px;  /* You can adjust this value based on your layout */
  z-index: 98;
  padding: 10px;
}
.sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  min-width: 17%;
  height: 100vh;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;
  z-index: 10;
}

.logo-img{
  width: 30%;
  height: 100%;
}

.sidebar.active {
  width: 60px;
}
.sidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}

.sidebar .logo-details i {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center;
}
.sidebar .logo-details .logo_name {
  color: #8C43D5;
  font-size: 24px;
  font-weight: 500;
}
.sidebar .nav-links {
  margin-top: 10px;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  height: 40px;
}

.sidebar .nav-links li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li a.active {
  background: #ffffff;
  border-bottom: 1px solid #8C43D5;
  border-radius: 5px;
}
.sidebar .nav-links li a:hover {
  background: #ffffff;
}
.sidebar .nav-links li i {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: #8C43D5;
}
.sidebar .nav-links li a .links_name {
  color: #8C43D5;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar .nav-links .log_out {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.home-section {
  background: #f5f5f5;
  min-height: 100vh;
  left: 240px;
  transition: all 0.5s ease;
}
.sidebar.active ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
}


@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
  }
  .sidebar.active {
    width: 220px;
  }
  .home-section {
    width: calc(100% - 60px);
    left: 60px;
  }
  .sidebar.active ~ .home-section {
    left: 220px;
    width: calc(100% - 220px);
    overflow: hidden;
  }
  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }
  .sidebar.active ~ .home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}
@media (max-width: 1150px) {
  .home-content .sales-boxes {
    flex-direction: column;
  }
  .home-content .sales-boxes .box {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  .home-content .sales-boxes .top-sales {
    margin: 0;
  }
}
@media (max-width: 1000px) {
  .overview-boxes .box {
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}

@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }


 
  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }
  .home-content .sales-boxes .sales-details {
    width: 560px;
  }
}
@media (max-width: 550px) {
  .overview-boxes .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .sidebar.active ~ .home-section nav .profile-details {
    display: none;
  }
}
@media (max-width: 400px) {
  .sidebar {
    width: 0;
  }
  .sidebar.active {
    width: 60px;
  }
  .home-section {
    width: 100%;
    left: 0;
  }
  .sidebar.active ~ .home-section {
    left: 60px;
    width: calc(100% - 60px);
  }
  .home-section nav {
    width: 100%;
    left: 0;
  }
  .sidebar.active ~ .home-section nav {
    left: 60px;
    width: calc(100% - 60px);
  }
}

.alert-quantity {
  color: red;
  font-size: 12px;
}

.btn {
  height: 35px;
  padding: 2px 10px;
  font-size: 14px;
}

th {
  font-size: 15px;
}

td {
  font-size: 14px;
}


.offcanvas {
  width: 34dvh;
  background-color: #ffaf87;
}

.toggle_btn {
  border: none;
  background-color: transparent;
}

.sidebar-menu-body {
  height: 100dvh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.sidebar-menu-nav-link {
  color: rgb(36, 36, 36);
  font-size: 18px;
  height: 38px;
  width: 100%;
  padding: 4px 14px;
  margin-top: 8px;
}

.sidebar-menu-nav-link:hover {
  color: white;
  background-color: #8C43D5;
  color: white;
  padding: 4px 14px;
  border-radius: 8px;
  height: 38px;
  transition: 300ms;
  /* background-color: #ffaf87; */
}

.sidebar-menu {
  font-size: 1rem;
}

.card__box {
  background-color: white;
  padding: 8px 14px;
  min-height: 80dvh;
  margin-top: 30px;
  margin-left: -10px;
}

.pagination__new {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-link {
  border: 2px solid #8C43D5;
  border-radius: 8px;
  color: #8C43D5;
}
.page-link:hover {
  background-color: #8C43D5;
  color: white;
}

.disabled {
  cursor: not-allowed;
}

.pagination {
  display: flex;
  justify-content: end;
  column-gap: 8px;
  margin-right: 30px;
}
.pagination > li > a {
  border: 1px solid #8C43D5;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  padding: 4px;
  text-align: center;
  font-size: 12px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #8C43D5;
  transition: 500ms;
  box-shadow: none;
}
.text-heading {
  color: #989898;
  font-size: 14px;
}
.text-details {
  color: #000000;
  margin-top: -10px;
  font-weight: 400;
  font-size: 16px;
}


body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.user-icon {
  transition: all 1s ease-in-out;
  /* border: 1px solid gray; */
  border-radius: 50%;
  cursor: pointer;
}

.user-icon:hover {
  box-shadow: 0px 8px 20px 0px #eaeaea;
  border-radius: 50%;
  border: 1px solid gray;
}

.submenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Adjust as needed */
  border: 1px solid #ccc; /* Adjust as needed */
}

.nav_items.open .submenu {
  display: block;
}

.submenu li {
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Adjust as needed */
}

.submenu li:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background-color: white;
    min-width: 50%;
    overflow-x: auto;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }


  .head h3 {
    font-size: 24px;
    font-weight: 450;
    letter-spacing: 1px;
    margin: 0%;
    padding: 0%;
  }
  .flag p {
    margin: 0%;
    color: white;
    font-weight: 500;
    padding: 5px;
    font-size: 15px;
    border-radius: 5px;
  }

  .custom-multiselect {
    width: 700px;
    border: 1px solid red;

    /* Add your custom styles here */
  }

  .custom-multiselect .p-multiselect-label {
    background-color: white;
    /* Add label styles */
  }

  .custom-multiselect .p-multiselect-items {
    /* Add dropdown items styles */
    background-color: grey;
    border: 1px solid red;
    color: #000000;
    z-index: 99;
  }

  .custom-multiselect .p-multiselect-item {
    /* Add individual dropdown item styles */
    background-color: grey;
    border: 1px solid red;
    color: #000000;
    z-index: 99;
  }



  .nav-logo {
    height: 65px;
    position: fixed;
    left: 9%;
    padding-top: 6px;
    background-color: white;
  }
}

@media only screen and (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    min-width: 17%;
   /* Set sidebar height to fill the viewport */
    overflow-x: hidden;
    overflow-y: auto;
  height: 100vh;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }


  .head h3 {
    font-size: 30px;
    font-weight: 450;
    letter-spacing: 1px;
    margin: 0%;
    padding: 0%;
  }

  .flag p {
    margin: 0%;
    color: white;
    font-weight: 500;
    padding: 8px;
    border-radius: 5px;
  }

  
  

  .nav-logo {
    height: 65px;
    position: fixed;
    left: 4%;
    padding-top: 6px;
    background-color: white;
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: white;
    min-width: 17%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y:auto;
    transition: all 0.5s ease-in-out;
    z-index: 10;
  }

  .head h3 {
    font-size: 30px;
    font-weight: 450;
    letter-spacing: 1px;
    margin: 0%;
    padding: 0%;
  }
  .flag p {
    margin: 0%;
    color: white;
    font-weight: 500;
    padding: 8px;
    border-radius: 5px;
  }

  
  
  

  .nav-logo {
    height: 65px;
    position: fixed;
    left: 2%;
    padding-top: 6px;
    background-color: white;
  }
}

.nav-lists {
  margin-top: 15%;
}

.nav_items {
  display: block;
  text-decoration: none;
  color: white;
  margin: 2% 0% 2% 0%;
  list-style-type: none;
  font-weight: 400;
  height: auto;
}

.top_item {
  margin-top: 26%;
}

.col-white {
  color: white !important;
}

.col-black {
  color: black !important;
}

.open_setting {
  position: relative;
}

@media only screen and (max-width: 600px) {
  .user-icon {
    font-size: 30px;
    margin-right: 4%;
  }

  .setting {
    position: fixed;
    top: 1%;
    font-size: 40px;
    padding: 7px;
    left: 75%;
    color: white;
    z-index: 6;
  }
  .col_overlay_min {
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0%;
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    overflow-x: hidden;
  }

  .show {
    display: block;
  }

  .nav_close {
    text-decoration: none;
    color: black;
    font-size: 44px;
    position: relative;
    top: 0px;
    left: 75%;
    cursor: pointer;
  }
  .gray {
    position: fixed;
    height: 100%;
    width: 8%;

    top: 0%;
    z-index: 5;
  }
}

@media only screen and (min-width: 768px) {
  .user-icon {
    font-size: 35px;
    margin-right: 2%;
  }

  .setting {
    display: none;
    position: fixed;
    top: 2%;
    font-size: 35px;
    padding: 6px;
    left: 1%;
    color: white;
    z-index: 6;
  }
  .col_overlay_min {
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0%;
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    overflow-x: hidden;
  }
  .show {
    display: block;
  }

  .nav_close {
    text-decoration: none;
    color: black;
    font-size: 44px;
    position: relative;
    top: 0px;
    left: 75%;
    cursor: pointer;
  }
  .gray {
    position: fixed;
    height: 100%;
    width: 6%;
    background-color: white;
    top: 0%;
    z-index: 5;
  }
}

@media only screen and (min-width: 1200px) {
  .user-icon {
    font-size: 35px;
    margin-right: 2%;
  }

  .setting {
    display: none;
    position: fixed;
    top: 2%;
    font-size: 35px;
    padding: 6px;
    left: 0.2%;
    color: white;
    z-index: 6;
  }
  .col_overlay_min {
    width: 100%;
    height: 100%;
    background-color: black;
    top: 0%;
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    overflow-x: hidden;
  }
  .show {
    display: block;
  }

  .nav_close {
    text-decoration: none;
    color: black;
    font-size: 44px;
    position: relative;
    top: 0px;
    left: 70%;
    cursor: pointer;
  }
  .gray {
    position: fixed;
    height: 100%;
    width: 3%;
    background-color: #490756;
    top: 0%;
    z-index: 5;
  }
}

.open_setting {
  position: relative;
}

.animate-rotate {
  position: fixed;
  animation: set linear 20s infinite forwards;
}

@keyframes set {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav_icons {
  margin-right: 6%;
  font-size: 22px;
}

.col_overlay {
  width: 250px;
  height: 100%;
  background-color: black;
  top: 0%;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
  overflow-x: hidden;
}

/* Top navBar Start */

.top_nav {
  background-color: white;
  position: fixed;
  top: 0%;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  z-index: 5;
}


/*hide / show Password*/
@media only screen and (max-width: 600px) {
  .sec_head {
    position: relative;
  }

  .eye_icon {
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 0%;
    right: 10%;
    margin-top: 7%;
    font-size: 20px;
    color: black;
  }
}
@media only screen and (min-width: 768px) {
  .sec_head {
    position: relative;
  }

  .eye_icon {
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 0%;
    overflow: hidden;
    right: 10%;
    margin-top: 5%;
    font-size: 20px;
    color: black;
  }
}
@media only screen and (min-width: 1200px) {
  .sec_head {
    position: relative;
  }

  .eye_icon {
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 0%;
    right: 10%;
    margin-top: 5%;
    font-size: 20px;
    color: black;
  }
}

.eye_icon:hover {
  background-color: #dbdbdb;
  padding: 3px;
  font-size: 20px;
  border-radius: 50%;
}


.white-shadow {
  position: relative;
  /* box-shadow: 0px 0px 14px 0px gray; */
  height: 80px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.a_text {
  position: absolute;
  color: white;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.white-shadow:hover {
  box-shadow: 0px 0px 14px 0px #bbbbbb;
  transform: translate(0px, -10px);
}

.dropdown_btn {
  background-color: transparent;
  color: white;
  margin-left: -12px;
}

.dropdown-cover {
  width: 100%;
  left: -10% !important;
}

/* scroll bars */
/* width */
::-webkit-scrollbar {
  width: 6px;
  opacity: 0.5;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(112, 150, 255);
}

/* scroll bars */

/* alert-message */
.alert-message {
  font-weight: 400;
  font-size: 14px;
  color: red;
}

@media only screen and (max-width: 600px) {
  .col_overlay2 {
    position: absolute;
    background-color: black;
    height: 26%;
    width: 100%;
    bottom: -206px;
    z-index: -9;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 768px) {
  .col_overlay2 {
    position: absolute;
    background-color: black;
    height: 26%;
    width: 100%;
    bottom: -206px;
    z-index: -9;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 1900px) {
  .col_overlay2 {
    position: absolute;
    background-color: black;
    height: 37.6%;
    width: 100%;
    bottom: -271px;
    z-index: -9;
    opacity: 0.5;
  }
}

@media only screen and (min-width: 1200px) {
  .col_overlay2 {
    position: absolute;
    background-color: black;
    height: 37.6%;
    width: 100%;
    bottom: -271px;
    z-index: -9;
    opacity: 0.5;
  }
}

/* user drop-down */
.user-p {
  position: fixed;
  background-color: rgb(253, 253, 253);
  z-index: 5;
  border-radius: 5px;
}
.drop-user {
  position: relative;
  overflow: hidden;
  display: none;
}

.user-text {
  list-style-type: none;
  color: black;
  padding-bottom: 10%;
}



.user-icon-style-mod {
  padding: 5%;
  font-size: 27px;
}

@media only screen and (max-width: 600px) {
  .user-p {
    right: 0%;
    padding: 5%;
    transform: translate(0px, 32%);
  }

}

@media only screen and (min-width: 768px) {
  .user-p {
    transform: translate(0px, 34%);
    right: 2%;
    padding: 2%;
  }

}

@media only screen and (min-width: 1200px) {
  .user-p {
    transform: translate(0px, 34%);
    right: 1%;
    padding: 1%;
  }


}

.showuser {
  display: block !important;
}

/* user drop */

.dropdown-toggle-mod {
  width: 100% !important;
  transform: translate(-16%, 0%) !important;
  background-color: transparent !important;
  color: black !important;
  border-color: white !important;
  margin-bottom: 0% !important;
}

.dropdown-menu-mod {
  border: none !important;
  width: 100% !important;
  transform: translate(-10%, 27%) !important;
}

.btn:focus {
  outline-color: white !important;
  outline: 5px solid white !important;
}

/* Side Navbar Dropdown Start  */

.navdrop {
  padding: 0%;
}

.dropdown-button {
  padding-top: 10%;
  text-decoration: none;
  color: white;
  margin-left: 0px;
  list-style-type: none;
  font-weight: 400;
  cursor: pointer;
}

.dropdown-list {
  padding: 0%;
  /* margin: 0% 5% 0% 0%; */
  /* border: 1px solid gray; */
  display: none;
  width: 100%;
  transform: translate(-9%, 0%);
  border-bottom: 1px solid black;
}

.dropdown-list-item {
  list-style-type: none;
  text-align: center;
  cursor: pointer;
  margin: 1% 0% 1% 0%;
  padding: 2% 0% 2% 0%;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.dropdown-list-item:hover {
  list-style-type: none;
  text-align: center;
  background-color: #666ee8;
  margin: 1% 0% 1% 0%;
  padding: 2% 0% 2% 0%;
  color: white;
  border-radius: 5px;
}

/* Side Navbar Dropdown End  */

/* 404 Error page */
.head-404 {
  font-size: 45px;
  font-weight: 600;
}

.error-page-text {
  color: #1a0f91;
  font-size: 35px;
  font-weight: 300;
}

.btn-purple {
  background-color: #7d97f4;
}

@media only screen and (max-width: 600px) {
  .single-card-img {
    height: 66px;
    width: 100%;
    border-bottom: 1px solid black;
  }

  .col-xs-6 {
    width: 50%;
  }

  .blog-img-single {
    height: 200px;
    width: 100%;
  }

  .centered-content {
    transform: translateY(0%);
  }
}

@media only screen and (min-width: 768px) {
  .single-card-img {
    height: 104px;
    width: 100%;
    border-bottom: 1px solid black;
  }

  .blog-img-single {
    height: 200px;
    width: 100%;
  }

  .centered-content {
    transform: translateY(90%);
  }
}

@media only screen and (min-width: 1200px) {
  .single-card-img {
    height: 250px;
    width: 100%;
    border-bottom: 1px solid black;
  }


  .blog-img-single {
    height: 200px;
    width: 100%;
  }

  .centered-content {
    transform: translateY(15%);
  }
}

/* ---------------- */

.single-card {
  background-color: #eeeeee;
  border-radius: 10px;
}

.single-card-img-profile {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.media-bg {
  /* border: 1px solid #d1d1d1; */
  padding: 3%;
}

.media-log {
  margin: 0%;
  color: black;
  font-size: 20px;
  font-weight: 350;
}

.profile-name {
  font-size: 20px;
  color: black;
  font-weight: 500;
}

.date-card {
  font-size: 15px;
  color: black;
  float: right;
}

.form-control-file {
  border: 1px solid #a6a9ae;
  line-height: 1.9;
  border-radius: 5px;
}

/* Post card */

@media only screen and (max-width: 600px) {
  .post-date-c {
    transform: translate(25%, -50%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-date {
    transform: translate(18%, -25%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-card {
    background-color: #ebebeb;
    padding: 5%;
    border-radius: 5px;
  }

  .post-image-media {
    width: 100%;
    height: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .post-date-c {
    transform: translate(20%, -55%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-date {
    transform: translate(14%, -25%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-card {
    background-color: #ebebeb;
    padding: 5%;
    border-radius: 5px;
  }

  .description-res {
    height: 65px;
  }

  .post-image-media {
    width: 100%;
    height: 250px;
  }
}

@media only screen and (min-width: 1200px) {
  .post-date {
    transform: translate(10%, -25%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-date-c {
    transform: translate(13%, -60%);
    font-size: 15px;
    color: gray;
    font-weight: 200;
  }

  .post-card {
    background-color: #ebebeb;
    padding: 5%;
    border-radius: 5px;
  }

  .description-res {
    height: 70px;
  }

  .post-image-media {
    width: 100%;
    height: 300px;
  }
}

.post-name {
  margin: 0%;
  font-size: 20px;
  font-weight: 500;
}

.post-icon {
  margin: 0% 3% 0% 0%;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.post-image-media {
  border-radius: 5px;
}

/* ----------- */

.text-edit-details {
  color: black;
  font-weight: 400;
  margin: 5% 0% 0% 5%;
}

.id-text {
  color: gray;
  font-size: 16px;
  font-weight: 400;
}

.btn-default-color {
  background-color: #303f9f;
  color: white;
}

.btn-default-color:hover {
  background-color: #303f9f;
  color: white;
}

.down-text {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

/* ---------------------- */

.logo-form {
  height: 100px;
  width: max-content;
  /* transform: translateX(70%); */
}

@media only screen and (max-width: 600px) {
  .response-cover {
    padding-left: 0px;
  }

  .card-section {
    width: 90%;
  }

  .logo-form-2 {
    display: block;
    height: 130px;
    width: max-content;
  }

  .h-logn-line {
    display: none;
  }

  .login-form-2 {
    width: 100%;
  }

  .div-logo {
    display: none;
  }

  .login-bg-image {
    position: fixed;
    /* background-image: url(img/login.jpg); */
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: right;
    opacity: 0.8;
  }

  .login-form {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 7px 10px 20px 0px #4f4f4f;
    padding: 2%;
    border-radius: 15px;
  }

  .login-form {
    width: 95%;
  }


}

@media only screen and (min-width: 768px) {
  .response-cover {
    padding-left: 0px;
  }

  .card-section {
    width: 50%;
  }

  .logo-form-2 {
    display: block;
    height: 180px;
    width: max-content;
  }

  .h-logn-line {
    display: none;
  }

  .login-form-2 {
    width: 100%;
  }

  .div-logo {
    display: none;
    height: 100px;
    width: max-content;
  }

  .login-bg-image {
    position: fixed;
    /* background-image: url(img/login.jpg); */
    height: 100%;
    width: 100%;
    /* background-size: cover; */
    opacity: 0.8;
    background-size: cover;
    background-position: center;
  }

  .login-form {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 7px 10px 20px 0px #4f4f4f;
    padding: 2%;
    border-radius: 15px;
  }

  .login-form {
    width: 45%;
  }

  .logo-section {
    width: 17%;
    height: 60px;
    background-color: white;
    position: fixed;
  
  }
}

@media only screen and (min-width: 1200px) {
  .response-cover {
    padding-left: 40px;
  }

  .card-section {
    width: 65%;
  }

  .logo-form-2 {
    display: none;
  }

  .h-logn-line {
    display: block;
  }
  .login-form-2 {
    width: 40%;
  }

  .div-logo {
    display: block;
    height: 100px;
    width: max-content;
  }

  .login-bg-image {
    position: fixed;
    /* background-image: url(img/login.jpg); */
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    opacity: 0.8;
  }

  .login-form {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 5%;
    transform: translate(-5%, -50%);
    box-shadow: 7px 10px 20px 0px #4f4f4f;
    padding: 2%;
    border-radius: 15px;
  }

  .login-form {
    width: 25%;
  }

  .logo-section {
    width: 17%;
    height: 65px;
    position: fixed;
    margin-top: 10px;

  }
}

.bg-overlay-orange {
  position: absolute;
  background-image:linear-gradient(red, yellow);
  height: -webkit-fill-available;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.bg-overlay-blue {
  position: absolute;
  background-color: blue;
  height: -webkit-fill-available;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.line-v {
  background-color: black;
  height: 30px;
  width: 2px;
  border-radius: 10px;
}

.line-h {
  background-color: gray;
  height: 2px;
  width: 70px;
  border-radius: 10px;
}

.line-mid-text {
  margin: 0% 2% 2% 2%;
  font-size: 20px;
  color: black;
  font-weight: 400;
}

.register-form {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 7px 10px 20px 0px #4f4f4f;
  padding: 2%;
  border-radius: 15px;
}

.login-main-head {
  font-size: 35px;
  color: black;
  font-weight: 300;
  padding: 4%;
}

.login-text-1 {
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.login-text-2 {
  margin: 0% 0% 1% 0%;
  font-size: 15px;
  color: black;
}

.login-btn {
  width: 90%;
  line-height: 2 !important;
  background-color: #f06900 !important;
  border-radius: 10px !important;
  border: transparent !important;
  color: white !important;
}

.login-btn-travel {
  width: 90%;
  line-height: 2 !important;
  background-color: #5454ff !important;
  border-radius: 10px !important;
  border: transparent !important;
  color: white !important;
}

input.form-control.text-black-ph::placeholder {
  color: black;
  font-size: 19px;
  font-weight: 400;
}



.heightlight-black {
  color: black;
  font-weight: 500;
  text-align: center;
}

.position-r {
  position: relative;
}

.eye_icon_login {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-15%, -50%);
  transition: all 0.4s ease-in-out;
  font-size: 20px;
  color: black;
}

.eye_icon_login:hover {
  background-color: #dbdbdb;
  padding: 3px;
  font-size: 20px;
  border-radius: 50%;
}

.full-image {
  border-radius: 5px;
  margin: 5px;
}

.login-form-2 {
  background-color: white;
  padding: 2%;
  border-radius: 15px;
}

.card-section {
  padding: 2%;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  box-shadow: 7px 8px 19px 0px #ebded8;
  transform: translate(-50%, -50%);
}

.h-logn-line {
  height: 450px;
  width: 2px;
  background-color: black;
  border-radius: 10px;
}



.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #f1f4f7;
}

.menu-title {
  color: rgb(12, 12, 12);
  display: inherit;
  padding: 2%;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.menu-title-link {
  color: #131212;
  font-weight: 500;
  margin-left: -30px;
  margin-top: 10px;
}

.active-link {
  color: #8C43D5;
  display: inherit;
  padding: 2%;
  transition: all 0.3s ease-in-out;
}

.active-link-link {
  color: #8C43D5;
  font-weight: 400;
  margin-left: -30px;
  margin-top: 10px;
}

.menu-title:hover {
  color: #8C43D5;
  display: inherit;
  /* background-color:#bbc7ff ; */
  /* padding: 2% 2% 2% 10%; */
  border-radius: 10px 0px 0px 10px;
}

.form-Questionnaire {
  padding: 5%;
}

.bg-color-blue {
  background-color: #f06900;
}

@media only screen and (max-width:600px){
    .support-box{
      padding: 10px 10px 0 20px;
      margin:2%;
      box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
      width: 100%;
      cursor: pointer;
      border-radius: 20px;
    }
    .logo-img{
      width: 50%;
      height: 100%;
    }
    .logo-section {
      width: 30%;
      height: 60px;
      background-color: white;
      position: fixed;
     
    
    }
  }

  @media only screen and (max-width:950px){
    .support-box{
      padding: 10px 10px 0 20px;
      margin:2%;
      box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
      width: 50%;
      cursor: pointer;
      border-radius: 20px;
    }
  }


  @media only screen and (min-width:600px){
.support-card{
  width:100%;
   height:auto;
   margin-left:10%;
   margin-top:20px;
 

   padding: 20px;
}

.support-box{
  box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
  padding: 30px 30px 10px 30px;
  margin:1%;
  width: 30%;
  cursor: pointer;
  border-radius: 20px;
}
}

.label {
  display : inline-block;
  margin-bottom : 0.4rem;
  font-weight: 500;
  color: rgb(83, 75, 75);

}

.btnsave1{
  background-color: #8C43D5 ;
  border : 1px solid transparent;
  max-height:36px;
  border-radius: 4px;
  min-width: 80px;
  color: white;
 }
 
 .btn1{
   background-color: #8C43D5 ;
   border : 1px solid transparent;
   color: white;
  }
  
  .bread-head1{
 font-weight: 500;
 color: rgb(83, 75, 75);
  }

  .modal-content {
  
 
     text-align: center;
padding-top:"50px";

   }


 .modal-title
 {
   margin-top: -20px;
   margin-left: -10px;
   
 }

 .modal-header
 {
  display: none;
 }
 
 .modal-footer
 {
  display: none;
 }

 .modal-backdrop.show
 {
   opacity: rgba(0,0,0,0.5);
 
 }



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.custom-button {
  display: inline-block;
  padding: 5px;
  border-radius:5px;
  background-color: #a020f0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.overviewcard
{
  width: 100%;
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.modalcancelBtn
{
  background-color: white !important;
  border: 1px solid #8C43D5 !important;
  color: #8C43D5 !important;
  box-shadow: none;
  border-radius: 10px;
  width: 80px;
  height: 35px;
  font-size: 14px;
}
.modalcancelBtn:hover {
  width: 81px;
  height: 36px;
  font-size: 14px;
  background-color: white !important;
  border: 1px solid #8C43D5 !important;
  color: #8C43D5 !important;
  box-shadow: #989898;

}

.modalsaveBtn
{
  background-color: #8C43D5 !important;
  border: 1px solid #8C43D5 !important;
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
  width: 80px;
  height: 35px;
  font-size: 14px;
}
.modalsaveBtn:hover {
  width: 81px;
  height: 36px;
  font-size: 14px;
  background-color: #8C43D5 !important;
  border: 1px solid #8C43D5 !important;
  color: #fff;
  box-shadow: #989898;

}
/* styles.css */




.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-linealign{
  min-width: 200px !important;
  text-align: center;
  }


/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue : #007BFF;
  --indigo : #6610F2;
  --purple : #6F42C1;
  --pink : #E83E8C;
  --red : #DC3545;
  --orange : #FD7E14;
  --yellow : #FFC107;
  --green : #28A745;
  --teal : #20C997;
  --cyan : #17A2B8;
  --white : #FFFFFF;
  --gray : #6C757D;
  --gray-dark : #343A40;
  --primary : #8C43D5;
  --secondary : #868E96;
  --success : #28D094;
  --info : #1E9FF2;
  --warning : #FF9149;
  --danger : #FF4961;
  --light : #F8F9FA;
  --dark : #343A40;
  --base : #9E9E9E;
  --breakpoint-xs : 0;
  --breakpoint-sm : 576px;
  --breakpoint-md : 768px;
  --breakpoint-lg : 992px;
  --breakpoint-xl : 1200px;
  --font-family-sans-serif : 'Montserrat', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-family-monospace : SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*, *::before, *::after {
  -webkit-box-sizing : border-box;
     -moz-box-sizing : border-box;
          box-sizing : border-box;
}

html {
  font-family : sans-serif;
  line-height : 1.15;
  -webkit-text-size-adjust : 100%;
      -ms-text-size-adjust : 100%;
  -ms-overflow-style : scrollbar;
  -webkit-tap-highlight-color : rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width : device-width;
}

article, aside, figcaption, figure, header, hgroup, main, nav, section {
  display : block;
}

body {
  margin : 0;
  font-family : 'Montserrat', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size : 1rem;
  font-weight : 400;
  line-height : 1.5;
  color : #212529;
  text-align : left;
  background-color : #F4F5FA;
}

[tabindex='-1']:focus {
  outline : 0 !important;
}

hr {
  -webkit-box-sizing : content-box;
     -moz-box-sizing : content-box;
          box-sizing : content-box;
  height : 0;
  overflow : visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top : 0;
  margin-bottom : 0.6rem;
}

p {
  margin-top : 0;
  margin-bottom : 0.5rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration : underline;
  text-decoration : underline dotted;
  cursor : help;
  border-bottom : 0;
}

address {
  margin-bottom : 1rem;
  font-style : normal;
  line-height : inherit;
}

ol, ul, dl {
  margin-top : 0;
  margin-bottom : 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom : 0;
}

dt {
  font-weight : 700;
}

dd {
  margin-bottom : 0.5rem;
  margin-left : 0;
}

blockquote {
  margin : 0 0 1rem;
}

dfn {
  font-style : italic;
}

b, strong {
  font-weight : bolder;
}

small {
  font-size : 80%;
}

sub, sup {
  position : relative;
  font-size : 75%;
  line-height : 0;
  vertical-align : baseline;
}

sub {
  bottom : -0.25em;
}

sup {
  top : -0.5em;
}

a {
  color : #8C43D5;
  text-decoration : none;
  background-color : transparent;
  -webkit-text-decoration-skip : objects;
}
a:hover {
  color : #232FDE;
  text-decoration : underline;
}

a:not([href]):not([tabindex]) {
  color : inherit;
  text-decoration : none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color : inherit;
  text-decoration : none;
}
a:not([href]):not([tabindex]):focus {
  outline : 0;
}

pre, code, kbd, samp {
  font-family : SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size : 1em;
}

pre {
  margin-top : 0;
  margin-bottom : 1rem;
  overflow : auto;
  -ms-overflow-style : scrollbar;
}

figure {
  margin : 0 0 1rem;
}

img {
  vertical-align : middle;
  border-style : none;
}

svg {
  overflow : hidden;
  vertical-align : middle;
}

table {
  border-collapse : collapse;
}

caption {
  padding-top : 0.75rem;
  padding-bottom : 0.75rem;
  color : #6C757D;
  text-align : left;
  caption-side : bottom;
}

th {
  text-align : inherit;
}



button {
  border-radius : 0;
}

button:focus {
  outline : 1px dotted;
  outline : 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin : 0;
  font-family : inherit;
  font-size : inherit;
  line-height : inherit;
}

button, input {
  overflow : visible;
}

button, select {
  text-transform : none;
}

button, html [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance : button;
}

button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner {
  padding : 0;
  border-style : none;
}

input[type='radio'], input[type='checkbox'] {
  -webkit-box-sizing : border-box;
     -moz-box-sizing : border-box;
          box-sizing : border-box;
  padding : 0;
}

input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
  -webkit-appearance : listbox;
}

textarea {
  overflow : auto;
  resize : vertical;
}

fieldset {
  min-width : 0;
  padding : 0;
  margin : 0;
  border : 0;
}

legend {
  display : block;
  width : 100%;
  max-width : 100%;
  padding : 0;
  margin-bottom : 0.5rem;
  font-size : 1.5rem;
  line-height : inherit;
  color : inherit;
  white-space : normal;
}

progress {
  vertical-align : baseline;
}

[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
  height : auto;
}

[type='search'] {
  outline-offset : -2px;
  -webkit-appearance : none;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance : none;
}

::-webkit-file-upload-button {
  font : inherit;
  -webkit-appearance : button;
}

output {
  display : inline-block;
}

summary {
  display : list-item;
  cursor : pointer;
}

template {
  display : none;
}

[hidden] {
  display : none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom : 0.75rem;
  font-family : inherit;
  font-weight : 500;
  line-height : 1.2;
  color : inherit;
}

h1, .h1 {
  font-size : 2.5rem;
}

h2, .h2 {
  font-size : 2rem;
}

h3, .h3 {
  font-size : 1.75rem;
}

h4, .h4 {
  font-size : 1.5rem;
}

h5, .h5 {
  font-size : 1.25rem;
}

h6, .h6 {
  font-size : 1rem;
}

.lead {
  font-size : 1.25rem;
  font-weight : 300;
}

.display-1 {
  font-size : 6rem;
  font-weight : 300;
  line-height : 1.2;
}

.display-2 {
  font-size : 5.5rem;
  font-weight : 300;
  line-height : 1.2;
}

.display-3 {
  font-size : 4.5rem;
  font-weight : 300;
  line-height : 1.2;
}

.display-4 {
  font-size : 3.5rem;
  font-weight : 300;
  line-height : 1.2;
}

hr {
  margin-top : 1.5rem;
  margin-bottom : 1.5rem;
  border : 0;
  border-top : 1px solid rgba(0, 0, 0, 0.1);
}

small, .small {
  font-size : 80%;
  font-weight : 400;
}

mark, .mark {
  padding : 0.2em;
  background-color : #FCF8E3;
}

.list-unstyled {
  padding-left : 0;
  list-style : none;
}

.list-inline {
  padding-left : 0;
  list-style : none;
}

.list-inline-item {
  display : inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right : 0.5rem;
}

.initialism {
  font-size : 90%;
  text-transform : uppercase;
}

.blockquote {
  margin-bottom : 1.5rem;
  font-size : 1.25rem;
}




.img-fluid {
  max-width : 100%;
  height : auto;
}

.img-thumbnail {
  padding : 0.25rem;
  background-color : #F4F5FA;
  border : 1px solid #dee2e6;
  border-radius : 0.25rem;
  max-width : 100%;
  height : auto;
}

.figure {
  display : inline-block;
}

.figure-img {
  margin-bottom : 0.75rem;
  line-height : 1;
}

.figure-caption {
  font-size : 90%;
  color : #6C757D;
}

code {
  font-size : 87.5%;
  color : #E83E8C;
  word-break : break-word;
}
a > code {
  color : inherit;
}

kbd {
  padding : 0.2rem 0.4rem;
  font-size : 87.5%;
  color : #FFFFFF;
  background-color : #212529;
  border-radius : 0.2rem;
}
kbd kbd {
  padding : 0;
  font-size : 100%;
  font-weight : 700;
}

pre {
  display : block;
  font-size : 87.5%;
  color : #212529;
}
pre code {
  font-size : inherit;
  color : inherit;
  word-break : normal;
}

.pre-scrollable {
  max-height : 340px;
  overflow-y : scroll;
}

.container {
  width : 100%;
  padding-right : 15px;
  padding-left : 15px;
  margin-right : auto;
  margin-left : auto;
}
@media (min-width: 576px) {
  .container {
    max-width : 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width : 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width : 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width : 1140px;
  }
}

.container-fluid {
  width : 98%;
  padding-right : 15px;
  padding-left : 15px;
  margin-right : auto;
  margin-left : auto;
}

.row {
  display : -webkit-box;
  display : -webkit-flex;
  display :    -moz-box;
  display : -ms-flexbox;
  display :         flex;
  -webkit-flex-wrap : wrap;
      -ms-flex-wrap : wrap;
          flex-wrap : wrap;
  margin-right : -15px;
  margin-left : -15px;
}

.no-gutters {
  margin-right : 0;
  margin-left : 0;
}
.no-gutters > .col, .no-gutters > [class*='col-'] {
  padding-right : 0;
  padding-left : 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position : relative;
  width : 100%;
  min-height : 1px;
  padding-right : 15px;
  padding-left : 15px;
}

.col {
  -webkit-flex-basis : 0;
  -ms-flex-preferred-size : 0;
          flex-basis : 0;
  -webkit-box-flex : 1;
  -webkit-flex-grow : 1;
     -moz-box-flex : 1;
  -ms-flex-positive : 1;
          flex-grow : 1;
  max-width : 100%;
}

.col-auto {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 auto;
     -moz-box-flex : 0;
      -ms-flex : 0 0 auto;
          flex : 0 0 auto;
  width : auto;
  max-width : none;
}

.col-1 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 8.33333%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 8.33333%;
          flex : 0 0 8.33333%;
  max-width : 8.33333%;
}

.col-2 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 16.66667%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 16.66667%;
          flex : 0 0 16.66667%;
  max-width : 16.66667%;
}

.col-3 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 25%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 25%;
          flex : 0 0 25%;
  max-width : 25%;
}

.col-4 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 33.33333%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 33.33333%;
          flex : 0 0 33.33333%;
  max-width : 33.33333%;
}

.col-5 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 41.66667%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 41.66667%;
          flex : 0 0 41.66667%;
  max-width : 41.66667%;
}

.col-6 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 50%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 50%;
          flex : 0 0 50%;
  max-width : 50%;
}

.col-7 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 58.33333%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 58.33333%;
          flex : 0 0 58.33333%;
  max-width : 58.33333%;
}

.col-8 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 66.66667%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 66.66667%;
          flex : 0 0 66.66667%;
  max-width : 66.66667%;
}

.col-9 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 75%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 75%;
          flex : 0 0 75%;
  max-width : 75%;
}

.col-10 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 83.33333%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 83.33333%;
          flex : 0 0 83.33333%;
  max-width : 83.33333%;
}

.col-11 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 91.66667%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 91.66667%;
          flex : 0 0 91.66667%;
  max-width : 91.66667%;
}

.col-12 {
  -webkit-box-flex : 0;
  -webkit-flex : 0 0 100%;
     -moz-box-flex : 0;
      -ms-flex : 0 0 100%;
          flex : 0 0 100%;
  max-width : 100%;
}

.order-first {
  -webkit-box-ordinal-group : 0;
  -webkit-order : -1;
     -moz-box-ordinal-group : 0;
  -ms-flex-order : -1;
          order : -1;
}

.order-last {
  -webkit-box-ordinal-group : 14;
  -webkit-order : 13;
     -moz-box-ordinal-group : 14;
  -ms-flex-order : 13;
          order : 13;
}

.order-0 {
  -webkit-box-ordinal-group : 1;
  -webkit-order : 0;
     -moz-box-ordinal-group : 1;
  -ms-flex-order : 0;
          order : 0;
}

.order-1 {
  -webkit-box-ordinal-group : 2;
  -webkit-order : 1;
     -moz-box-ordinal-group : 2;
  -ms-flex-order : 1;
          order : 1;
}

.order-2 {
  -webkit-box-ordinal-group : 3;
  -webkit-order : 2;
     -moz-box-ordinal-group : 3;
  -ms-flex-order : 2;
          order : 2;
}

.order-3 {
  -webkit-box-ordinal-group : 4;
  -webkit-order : 3;
     -moz-box-ordinal-group : 4;
  -ms-flex-order : 3;
          order : 3;
}

.order-4 {
  -webkit-box-ordinal-group : 5;
  -webkit-order : 4;
     -moz-box-ordinal-group : 5;
  -ms-flex-order : 4;
          order : 4;
}

.order-5 {
  -webkit-box-ordinal-group : 6;
  -webkit-order : 5;
     -moz-box-ordinal-group : 6;
  -ms-flex-order : 5;
          order : 5;
}

.order-6 {
  -webkit-box-ordinal-group : 7;
  -webkit-order : 6;
     -moz-box-ordinal-group : 7;
  -ms-flex-order : 6;
          order : 6;
}

.order-7 {
  -webkit-box-ordinal-group : 8;
  -webkit-order : 7;
     -moz-box-ordinal-group : 8;
  -ms-flex-order : 7;
          order : 7;
}

.order-8 {
  -webkit-box-ordinal-group : 9;
  -webkit-order : 8;
     -moz-box-ordinal-group : 9;
  -ms-flex-order : 8;
          order : 8;
}

.order-9 {
  -webkit-box-ordinal-group : 10;
  -webkit-order : 9;
     -moz-box-ordinal-group : 10;
  -ms-flex-order : 9;
          order : 9;
}

.order-10 {
  -webkit-box-ordinal-group : 11;
  -webkit-order : 10;
     -moz-box-ordinal-group : 11;
  -ms-flex-order : 10;
          order : 10;
}

.order-11 {
  -webkit-box-ordinal-group : 12;
  -webkit-order : 11;
     -moz-box-ordinal-group : 12;
  -ms-flex-order : 11;
          order : 11;
}

.order-12 {
  -webkit-box-ordinal-group : 13;
  -webkit-order : 12;
     -moz-box-ordinal-group : 13;
  -ms-flex-order : 12;
          order : 12;
}

.offset-1 {
  margin-left : 8.33333%;
}

.offset-2 {
  margin-left : 16.66667%;
}

.offset-3 {
  margin-left : 25%;
}

.offset-4 {
  margin-left : 33.33333%;
}

.offset-5 {
  margin-left : 41.66667%;
}

.offset-6 {
  margin-left : 50%;
}

.offset-7 {
  margin-left : 58.33333%;
}

.offset-8 {
  margin-left : 66.66667%;
}

.offset-9 {
  margin-left : 75%;
}

.offset-10 {
  margin-left : 83.33333%;
}

.offset-11 {
  margin-left : 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis : 0;
    -ms-flex-preferred-size : 0;
            flex-basis : 0;
    -webkit-box-flex : 1;
    -webkit-flex-grow : 1;
       -moz-box-flex : 1;
    -ms-flex-positive : 1;
            flex-grow : 1;
    max-width : 100%;
  }
  .col-sm-auto {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 auto;
       -moz-box-flex : 0;
        -ms-flex : 0 0 auto;
            flex : 0 0 auto;
    width : auto;
    max-width : none;
  }
  .col-sm-1 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 8.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 8.33333%;
            flex : 0 0 8.33333%;
    max-width : 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 16.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 16.66667%;
            flex : 0 0 16.66667%;
    max-width : 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 25%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 25%;
            flex : 0 0 25%;
    max-width : 25%;
  }
  .col-sm-4 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 33.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 33.33333%;
            flex : 0 0 33.33333%;
    max-width : 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 41.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 41.66667%;
            flex : 0 0 41.66667%;
    max-width : 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 50%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 50%;
            flex : 0 0 50%;
    max-width : 50%;
  }
  .col-sm-7 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 58.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 58.33333%;
            flex : 0 0 58.33333%;
    max-width : 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 66.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 66.66667%;
            flex : 0 0 66.66667%;
    max-width : 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 75%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 75%;
            flex : 0 0 75%;
    max-width : 75%;
  }
  .col-sm-10 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 83.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 83.33333%;
            flex : 0 0 83.33333%;
    max-width : 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 91.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 91.66667%;
            flex : 0 0 91.66667%;
    max-width : 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 100%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 100%;
            flex : 0 0 100%;
    max-width : 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group : 0;
    -webkit-order : -1;
       -moz-box-ordinal-group : 0;
    -ms-flex-order : -1;
            order : -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group : 14;
    -webkit-order : 13;
       -moz-box-ordinal-group : 14;
    -ms-flex-order : 13;
            order : 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group : 1;
    -webkit-order : 0;
       -moz-box-ordinal-group : 1;
    -ms-flex-order : 0;
            order : 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group : 2;
    -webkit-order : 1;
       -moz-box-ordinal-group : 2;
    -ms-flex-order : 1;
            order : 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group : 3;
    -webkit-order : 2;
       -moz-box-ordinal-group : 3;
    -ms-flex-order : 2;
            order : 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group : 4;
    -webkit-order : 3;
       -moz-box-ordinal-group : 4;
    -ms-flex-order : 3;
            order : 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group : 5;
    -webkit-order : 4;
       -moz-box-ordinal-group : 5;
    -ms-flex-order : 4;
            order : 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group : 6;
    -webkit-order : 5;
       -moz-box-ordinal-group : 6;
    -ms-flex-order : 5;
            order : 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group : 7;
    -webkit-order : 6;
       -moz-box-ordinal-group : 7;
    -ms-flex-order : 6;
            order : 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group : 8;
    -webkit-order : 7;
       -moz-box-ordinal-group : 8;
    -ms-flex-order : 7;
            order : 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group : 9;
    -webkit-order : 8;
       -moz-box-ordinal-group : 9;
    -ms-flex-order : 8;
            order : 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group : 10;
    -webkit-order : 9;
       -moz-box-ordinal-group : 10;
    -ms-flex-order : 9;
            order : 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group : 11;
    -webkit-order : 10;
       -moz-box-ordinal-group : 11;
    -ms-flex-order : 10;
            order : 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group : 12;
    -webkit-order : 11;
       -moz-box-ordinal-group : 12;
    -ms-flex-order : 11;
            order : 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group : 13;
    -webkit-order : 12;
       -moz-box-ordinal-group : 13;
    -ms-flex-order : 12;
            order : 12;
  }
  .offset-sm-0 {
    margin-left : 0;
  }
  .offset-sm-1 {
    margin-left : 8.33333%;
  }
  .offset-sm-2 {
    margin-left : 16.66667%;
  }
  .offset-sm-3 {
    margin-left : 25%;
  }
  .offset-sm-4 {
    margin-left : 33.33333%;
  }
  .offset-sm-5 {
    margin-left : 41.66667%;
  }
  .offset-sm-6 {
    margin-left : 50%;
  }
  .offset-sm-7 {
    margin-left : 58.33333%;
  }
  .offset-sm-8 {
    margin-left : 66.66667%;
  }
  .offset-sm-9 {
    margin-left : 75%;
  }
  .offset-sm-10 {
    margin-left : 83.33333%;
  }
  .offset-sm-11 {
    margin-left : 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis : 0;
    -ms-flex-preferred-size : 0;
            flex-basis : 0;
    -webkit-box-flex : 1;
    -webkit-flex-grow : 1;
       -moz-box-flex : 1;
    -ms-flex-positive : 1;
            flex-grow : 1;
    max-width : 100%;
  }
  .col-md-auto {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 auto;
       -moz-box-flex : 0;
        -ms-flex : 0 0 auto;
            flex : 0 0 auto;
    width : auto;
    max-width : none;
  }
  .col-md-1 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 8.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 8.33333%;
            flex : 0 0 8.33333%;
    max-width : 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 16.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 16.66667%;
            flex : 0 0 16.66667%;
    max-width : 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 25%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 25%;
            flex : 0 0 25%;
    max-width : 25%;
  }
  .col-md-4 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 33.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 33.33333%;
            flex : 0 0 33.33333%;
    max-width : 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 41.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 41.66667%;
            flex : 0 0 41.66667%;
    max-width : 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 50%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 50%;
            flex : 0 0 50%;
    max-width : 50%;
  }
  .col-md-7 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 58.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 58.33333%;
            flex : 0 0 58.33333%;
    max-width : 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 66.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 66.66667%;
            flex : 0 0 66.66667%;
    max-width : 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 75%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 75%;
            flex : 0 0 75%;
    max-width : 75%;
  }
  .col-md-10 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 83.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 83.33333%;
            flex : 0 0 83.33333%;
    max-width : 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 91.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 91.66667%;
            flex : 0 0 91.66667%;
    max-width : 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 100%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 100%;
            flex : 0 0 100%;
    max-width : 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group : 0;
    -webkit-order : -1;
       -moz-box-ordinal-group : 0;
    -ms-flex-order : -1;
            order : -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group : 14;
    -webkit-order : 13;
       -moz-box-ordinal-group : 14;
    -ms-flex-order : 13;
            order : 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group : 1;
    -webkit-order : 0;
       -moz-box-ordinal-group : 1;
    -ms-flex-order : 0;
            order : 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group : 2;
    -webkit-order : 1;
       -moz-box-ordinal-group : 2;
    -ms-flex-order : 1;
            order : 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group : 3;
    -webkit-order : 2;
       -moz-box-ordinal-group : 3;
    -ms-flex-order : 2;
            order : 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group : 4;
    -webkit-order : 3;
       -moz-box-ordinal-group : 4;
    -ms-flex-order : 3;
            order : 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group : 5;
    -webkit-order : 4;
       -moz-box-ordinal-group : 5;
    -ms-flex-order : 4;
            order : 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group : 6;
    -webkit-order : 5;
       -moz-box-ordinal-group : 6;
    -ms-flex-order : 5;
            order : 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group : 7;
    -webkit-order : 6;
       -moz-box-ordinal-group : 7;
    -ms-flex-order : 6;
            order : 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group : 8;
    -webkit-order : 7;
       -moz-box-ordinal-group : 8;
    -ms-flex-order : 7;
            order : 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group : 9;
    -webkit-order : 8;
       -moz-box-ordinal-group : 9;
    -ms-flex-order : 8;
            order : 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group : 10;
    -webkit-order : 9;
       -moz-box-ordinal-group : 10;
    -ms-flex-order : 9;
            order : 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group : 11;
    -webkit-order : 10;
       -moz-box-ordinal-group : 11;
    -ms-flex-order : 10;
            order : 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group : 12;
    -webkit-order : 11;
       -moz-box-ordinal-group : 12;
    -ms-flex-order : 11;
            order : 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group : 13;
    -webkit-order : 12;
       -moz-box-ordinal-group : 13;
    -ms-flex-order : 12;
            order : 12;
  }
  .offset-md-0 {
    margin-left : 0;
  }
  .offset-md-1 {
    margin-left : 8.33333%;
  }
  .offset-md-2 {
    margin-left : 16.66667%;
  }
  .offset-md-3 {
    margin-left : 25%;
  }
  .offset-md-4 {
    margin-left : 33.33333%;
  }
  .offset-md-5 {
    margin-left : 41.66667%;
  }
  .offset-md-6 {
    margin-left : 50%;
  }
  .offset-md-7 {
    margin-left : 58.33333%;
  }
  .offset-md-8 {
    margin-left : 66.66667%;
  }
  .offset-md-9 {
    margin-left : 75%;
  }
  .offset-md-10 {
    margin-left : 83.33333%;
  }
  .offset-md-11 {
    margin-left : 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis : 0;
    -ms-flex-preferred-size : 0;
            flex-basis : 0;
    -webkit-box-flex : 1;
    -webkit-flex-grow : 1;
       -moz-box-flex : 1;
    -ms-flex-positive : 1;
            flex-grow : 1;
    max-width : 100%;
  }
  .col-lg-auto {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 auto;
       -moz-box-flex : 0;
        -ms-flex : 0 0 auto;
            flex : 0 0 auto;
    width : auto;
    max-width : none;
  }
  .col-lg-1 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 8.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 8.33333%;
            flex : 0 0 8.33333%;
    max-width : 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 16.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 16.66667%;
            flex : 0 0 16.66667%;
    max-width : 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 25%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 25%;
            flex : 0 0 25%;
    max-width : 25%;
  }
  .col-lg-4 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 33.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 33.33333%;
            flex : 0 0 33.33333%;
    max-width : 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 41.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 41.66667%;
            flex : 0 0 41.66667%;
    max-width : 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 50%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 50%;
            flex : 0 0 50%;
    max-width : 50%;
  }
  .col-lg-7 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 58.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 58.33333%;
            flex : 0 0 58.33333%;
    max-width : 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 66.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 66.66667%;
            flex : 0 0 66.66667%;
    max-width : 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 75%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 75%;
            flex : 0 0 75%;
    max-width : 75%;
  }
  .col-lg-10 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 83.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 83.33333%;
            flex : 0 0 83.33333%;
    max-width : 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 91.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 91.66667%;
            flex : 0 0 91.66667%;
    max-width : 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 100%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 100%;
            flex : 0 0 100%;
    max-width : 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group : 0;
    -webkit-order : -1;
       -moz-box-ordinal-group : 0;
    -ms-flex-order : -1;
            order : -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group : 14;
    -webkit-order : 13;
       -moz-box-ordinal-group : 14;
    -ms-flex-order : 13;
            order : 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group : 1;
    -webkit-order : 0;
       -moz-box-ordinal-group : 1;
    -ms-flex-order : 0;
            order : 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group : 2;
    -webkit-order : 1;
       -moz-box-ordinal-group : 2;
    -ms-flex-order : 1;
            order : 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group : 3;
    -webkit-order : 2;
       -moz-box-ordinal-group : 3;
    -ms-flex-order : 2;
            order : 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group : 4;
    -webkit-order : 3;
       -moz-box-ordinal-group : 4;
    -ms-flex-order : 3;
            order : 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group : 5;
    -webkit-order : 4;
       -moz-box-ordinal-group : 5;
    -ms-flex-order : 4;
            order : 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group : 6;
    -webkit-order : 5;
       -moz-box-ordinal-group : 6;
    -ms-flex-order : 5;
            order : 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group : 7;
    -webkit-order : 6;
       -moz-box-ordinal-group : 7;
    -ms-flex-order : 6;
            order : 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group : 8;
    -webkit-order : 7;
       -moz-box-ordinal-group : 8;
    -ms-flex-order : 7;
            order : 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group : 9;
    -webkit-order : 8;
       -moz-box-ordinal-group : 9;
    -ms-flex-order : 8;
            order : 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group : 10;
    -webkit-order : 9;
       -moz-box-ordinal-group : 10;
    -ms-flex-order : 9;
            order : 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group : 11;
    -webkit-order : 10;
       -moz-box-ordinal-group : 11;
    -ms-flex-order : 10;
            order : 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group : 12;
    -webkit-order : 11;
       -moz-box-ordinal-group : 12;
    -ms-flex-order : 11;
            order : 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group : 13;
    -webkit-order : 12;
       -moz-box-ordinal-group : 13;
    -ms-flex-order : 12;
            order : 12;
  }
  .offset-lg-0 {
    margin-left : 0;
  }
  .offset-lg-1 {
    margin-left : 8.33333%;
  }
  .offset-lg-2 {
    margin-left : 16.66667%;
  }
  .offset-lg-3 {
    margin-left : 25%;
  }
  .offset-lg-4 {
    margin-left : 33.33333%;
  }
  .offset-lg-5 {
    margin-left : 41.66667%;
  }
  .offset-lg-6 {
    margin-left : 50%;
  }
  .offset-lg-7 {
    margin-left : 58.33333%;
  }
  .offset-lg-8 {
    margin-left : 66.66667%;
  }
  .offset-lg-9 {
    margin-left : 75%;
  }
  .offset-lg-10 {
    margin-left : 83.33333%;
  }
  .offset-lg-11 {
    margin-left : 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis : 0;
    -ms-flex-preferred-size : 0;
            flex-basis : 0;
    -webkit-box-flex : 1;
    -webkit-flex-grow : 1;
       -moz-box-flex : 1;
    -ms-flex-positive : 1;
            flex-grow : 1;
    max-width : 100%;
  }
  .col-xl-auto {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 auto;
       -moz-box-flex : 0;
        -ms-flex : 0 0 auto;
            flex : 0 0 auto;
    width : auto;
    max-width : none;
  }
  .col-xl-1 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 8.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 8.33333%;
            flex : 0 0 8.33333%;
    max-width : 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 16.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 16.66667%;
            flex : 0 0 16.66667%;
    max-width : 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 25%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 25%;
            flex : 0 0 25%;
    max-width : 25%;
  }
  .col-xl-4 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 33.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 33.33333%;
            flex : 0 0 33.33333%;
    max-width : 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 41.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 41.66667%;
            flex : 0 0 41.66667%;
    max-width : 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 50%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 50%;
            flex : 0 0 50%;
    max-width : 50%;
  }
  .col-xl-7 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 58.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 58.33333%;
            flex : 0 0 58.33333%;
    max-width : 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 66.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 66.66667%;
            flex : 0 0 66.66667%;
    max-width : 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 75%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 75%;
            flex : 0 0 75%;
    max-width : 75%;
  }
  .col-xl-10 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 83.33333%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 83.33333%;
            flex : 0 0 83.33333%;
    max-width : 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 91.66667%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 91.66667%;
            flex : 0 0 91.66667%;
    max-width : 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 100%;
       -moz-box-flex : 0;
        -ms-flex : 0 0 100%;
            flex : 0 0 100%;
    max-width : 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group : 0;
    -webkit-order : -1;
       -moz-box-ordinal-group : 0;
    -ms-flex-order : -1;
            order : -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group : 14;
    -webkit-order : 13;
       -moz-box-ordinal-group : 14;
    -ms-flex-order : 13;
            order : 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group : 1;
    -webkit-order : 0;
       -moz-box-ordinal-group : 1;
    -ms-flex-order : 0;
            order : 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group : 2;
    -webkit-order : 1;
       -moz-box-ordinal-group : 2;
    -ms-flex-order : 1;
            order : 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group : 3;
    -webkit-order : 2;
       -moz-box-ordinal-group : 3;
    -ms-flex-order : 2;
            order : 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group : 4;
    -webkit-order : 3;
       -moz-box-ordinal-group : 4;
    -ms-flex-order : 3;
            order : 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group : 5;
    -webkit-order : 4;
       -moz-box-ordinal-group : 5;
    -ms-flex-order : 4;
            order : 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group : 6;
    -webkit-order : 5;
       -moz-box-ordinal-group : 6;
    -ms-flex-order : 5;
            order : 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group : 7;
    -webkit-order : 6;
       -moz-box-ordinal-group : 7;
    -ms-flex-order : 6;
            order : 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group : 8;
    -webkit-order : 7;
       -moz-box-ordinal-group : 8;
    -ms-flex-order : 7;
            order : 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group : 9;
    -webkit-order : 8;
       -moz-box-ordinal-group : 9;
    -ms-flex-order : 8;
            order : 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group : 10;
    -webkit-order : 9;
       -moz-box-ordinal-group : 10;
    -ms-flex-order : 9;
            order : 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group : 11;
    -webkit-order : 10;
       -moz-box-ordinal-group : 11;
    -ms-flex-order : 10;
            order : 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group : 12;
    -webkit-order : 11;
       -moz-box-ordinal-group : 12;
    -ms-flex-order : 11;
            order : 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group : 13;
    -webkit-order : 12;
       -moz-box-ordinal-group : 13;
    -ms-flex-order : 12;
            order : 12;
  }
  .offset-xl-0 {
    margin-left : 0;
  }
  .offset-xl-1 {
    margin-left : 8.33333%;
  }
  .offset-xl-2 {
    margin-left : 16.66667%;
  }
  .offset-xl-3 {
    margin-left : 25%;
  }
  .offset-xl-4 {
    margin-left : 33.33333%;
  }
  .offset-xl-5 {
    margin-left : 41.66667%;
  }
  .offset-xl-6 {
    margin-left : 50%;
  }
  .offset-xl-7 {
    margin-left : 58.33333%;
  }
  .offset-xl-8 {
    margin-left : 66.66667%;
  }
  .offset-xl-9 {
    margin-left : 75%;
  }
  .offset-xl-10 {
    margin-left : 83.33333%;
  }
  .offset-xl-11 {
    margin-left : 91.66667%;
  }
}

.table {
  width : 100%;
  margin-bottom : 1.5rem;
  background-color : transparent;
}
.table th, .table td {
  padding : 0.75rem;
  vertical-align : top;
  border-top : 1px solid #dee2e6;
}
.table thead th {
  vertical-align : bottom;
  border-bottom : 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top : 2px solid #dee2e6;
}
.table .table {
  background-color : #F4F5FA;
}

.table-sm th, .table-sm td {
  padding : 0.3rem;
}

.table-bordered {
  border : 1px solid #dee2e6;
}
.table-bordered th, .table-bordered td {
  border : 1px solid #dee2e6;
}
.table-bordered thead th, .table-bordered thead td {
  border-bottom-width : 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border : 0;
}


.table-striped tbody tr:nth-of-type(odd) {
  background-color :#fff !important;
}

.table-hover tbody tr:hover {
  background-color : rgba(0, 0, 255, 0.037);
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color : #D4D6F9;
}

.table-hover .table-primary:hover {
  background-color : #BEC1F6;
}
.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color : #BEC1F6;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color : #DDDFE2;
}

.table-hover .table-secondary:hover {
  background-color : #CFD2D6;
}
.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color : #CFD2D6;
}

.table-success, .table-success > th, .table-success > td {
  background-color : #C3F2E1;
}

.table-hover .table-success:hover {
  background-color : #AEEDD7;
}
.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color : #AEEDD7;
}

.table-info, .table-info > th, .table-info > td {
  background-color : #C0E4FB;
}

.table-hover .table-info:hover {
  background-color : #A8DAF9;
}
.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color : #A8DAF9;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color : #FFE0CC;
}

.table-hover .table-warning:hover {
  background-color : #FFD1B3;
}
.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color : #FFD1B3;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color : #FFCCD3;
}

.table-hover .table-danger:hover {
  background-color : #FFB3BD;
}
.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color : #FFB3BD;
}

.table-light, .table-light > th, .table-light > td {
  background-color : #FDFDFE;
}

.table-hover .table-light:hover {
  background-color : #ECECF6;
}
.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color : #ECECF6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color : #C6C8CA;
}

.table-hover .table-dark:hover {
  background-color : #B9BBBE;
}
.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color : #B9BBBE;
}

.table-base, .table-base > th, .table-base > td {
  background-color : #E4E4E4;
}

.table-hover .table-base:hover {
  background-color : #D7D7D7;
}
.table-hover .table-base:hover > td, .table-hover .table-base:hover > th {
  background-color : #D7D7D7;
}

.table-active, .table-active > th, .table-active > td {
  background-color : rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color : rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color : rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color : #F4F5FA;
  background-color : #212529;
  border-color : #32383E;
}

.table .thead-light th {
  color : #495057;
  background-color : #E9ECEF;
  border-color : #dee2e6;
}

.table-dark {
  color : #F4F5FA;
  background-color : #212529;
}
.table-dark th, .table-dark td, .table-dark thead th {
  border-color : #32383E;
}
.table-dark.table-bordered {
  border : 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color : rgb(0,0,0);
}
.table-dark.table-hover tbody tr:hover {
  background-color : #d7f5e4;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display : block;
    width : 100%;
    overflow-x : auto;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style : -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border : 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display : block;
    width : 100%;
    overflow-x : auto;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style : -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border : 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display : block;
    width : 100%;
    overflow-x : auto;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style : -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border : 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display : block;
    width : 100%;
    overflow-x : auto;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style : -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border : 0;
  }
}

.table-responsive {
  display : block;
  width : 100%;
  overflow-x : auto;
  -webkit-overflow-scrolling : touch;
  -ms-overflow-style : -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border : 0;
}



.form-control::-ms-expand {
  background-color : transparent;
  border : 0;
}
.form-control:focus {
  color : #495057;
  background-color : #FFFFFF;
  border-color : #D5D7F9;
  outline : 0;
  -webkit-box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
          box-shadow : 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
}
.form-control::-webkit-input-placeholder {
  color : #6C757D;
  opacity : 1;
}
.form-control:-moz-placeholder {
  color : #6C757D;
  opacity : 1;
}
.form-control::-moz-placeholder {
  color : #6C757D;
  opacity : 1;
}
.form-control:-ms-input-placeholder {
  color : #6C757D;
  opacity : 1;
}
.form-control::placeholder {
  color : #6C757D;
  opacity : 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color : #E9ECEF;
  opacity : 1;
}

select.form-control:focus::-ms-value {
  color : #495057;
  background-color : #FFFFFF;
}

.form-control-file, .form-control-range {
  display : block;
  width : 100%;
}



.form-control-plaintext {
  display : block;
  width : 100%;
  padding-top : 0.375rem;
  padding-bottom : 0.375rem;
  margin-bottom : 0;
  line-height : 1.5;
  color : #212529;
  background-color : transparent;
  border : solid transparent;
  border-width : 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right : 0;
  padding-left : 0;
}

.form-control-sm {
  height : -webkit-calc(1.8125rem + 2px);
  height :    -moz-calc(1.8125rem + 2px);
  height :         calc(1.8125rem + 2px);
  padding : 0.25rem 0.5rem;
  font-size : 0.875rem;
  line-height : 1.5;
  border-radius : 0.2rem;
}

.form-control-lg {
  height : -webkit-calc(2.875rem + 2px);
  height :    -moz-calc(2.875rem + 2px);
  height :         calc(2.875rem + 2px);
  padding : 0.5rem 1rem;
  font-size : 1.25rem;
  line-height : 1.5;
  border-radius : 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height : auto;
}

textarea.form-control {
  height : auto;
}

.form-group {
  margin-bottom : 1rem;
}

.form-text {
  display : block;
  margin-top : 0.25rem;
}

.form-row {
  display : -webkit-box;
  display : -webkit-flex;
  display :    -moz-box;
  display : -ms-flexbox;
  display :         flex;
  -webkit-flex-wrap : wrap;
      -ms-flex-wrap : wrap;
          flex-wrap : wrap;
  margin-right : -5px;
  margin-left : -5px;
}
.form-row > .col, .form-row > [class*='col-'] {
  padding-right : 5px;
  padding-left : 5px;
}

.form-check {
  position : relative;
  display : block;
  padding-left : 1.25rem;
}

.form-check-input {
  position : absolute;
  margin-top : 0.3rem;
  margin-left : -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color : #6C757D;
}

.form-check-label {
  margin-bottom : 0;
}

.form-check-inline {
  display : -webkit-inline-box;
  display : -webkit-inline-flex;
  display :    -moz-inline-box;
  display : -ms-inline-flexbox;
  display :         inline-flex;
  -webkit-box-align : center;
  -webkit-align-items : center;
     -moz-box-align : center;
  -ms-flex-align : center;
          align-items : center;
  padding-left : 0;
  margin-right : 0.75rem;
}
.form-check-inline .form-check-input {
  position : static;
  margin-top : 0;
  margin-right : 0.3125rem;
  margin-left : 0;
}

.valid-feedback {
  display : none;
  width : 100%;
  margin-top : 0.25rem;
  font-size : 80%;
  color : #28D094;
}

.valid-tooltip {
  position : absolute;
  top : 100%;
  z-index : 5;
  display : none;
  max-width : 100%;
  padding : 0.25rem 0.5rem;
  margin-top : 0.1rem;
  font-size : 0.875rem;
  line-height : 1.5;
  color : #212529;
  background-color : rgba(40, 208, 148, 0.9);
  border-radius : 0.25rem;
}

/* .was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid, .custom-select.is-valid {
  border-color : #28D094;
} */
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color : #28D094;
  -webkit-box-shadow : 0 0 0 0.2rem rgba(40, 208, 148, 0.25);
          box-shadow : 0 0 0 0.2rem rgba(40, 208, 148, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback, .was-validated
  .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip {
  display : block;
}

.was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip {
  display : block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color : #28D094;
}


.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid, .custom-select.is-invalid {
  border-color : #FF4961;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color : #FF4961;
  -webkit-box-shadow : 0 0 0 0.2rem rgba(255, 73, 97, 0.25);
          box-shadow : 0 0 0 0.2rem rgba(255, 73, 97, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback, .was-validated
  .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
  display : block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip {
  display : block;
}

@media (min-width: 576px) {
  .form-inline label {
    display : -webkit-box;
    display : -webkit-flex;
    display :    -moz-box;
    display : -ms-flexbox;
    display :         flex;
    -webkit-box-align : center;
    -webkit-align-items : center;
       -moz-box-align : center;
    -ms-flex-align : center;
            align-items : center;
    -webkit-box-pack : center;
    -webkit-justify-content : center;
       -moz-box-pack : center;
    -ms-flex-pack : center;
            justify-content : center;
    margin-bottom : 0;
  }
  .form-inline .form-group {
    display : -webkit-box;
    display : -webkit-flex;
    display :    -moz-box;
    display : -ms-flexbox;
    display :         flex;
    -webkit-box-flex : 0;
    -webkit-flex : 0 0 auto;
       -moz-box-flex : 0;
        -ms-flex : 0 0 auto;
            flex : 0 0 auto;
    -webkit-box-orient : horizontal;
    -webkit-box-direction : normal;
    -webkit-flex-flow : row wrap;
       -moz-box-orient : horizontal;
       -moz-box-direction : normal;
        -ms-flex-flow : row wrap;
            flex-flow : row wrap;
    -webkit-box-align : center;
    -webkit-align-items : center;
       -moz-box-align : center;
    -ms-flex-align : center;
            align-items : center;
    margin-bottom : 0;
  }
  .form-inline .form-control {
    display : inline-block;
    width : auto;
    vertical-align : middle;
  }
  .form-inline .form-control-plaintext {
    display : inline-block;
  }
  .form-inline .input-group, .form-inline .custom-select {
    width : auto;
  }
  .form-inline .form-check {
    display : -webkit-box;
    display : -webkit-flex;
    display :    -moz-box;
    display : -ms-flexbox;
    display :         flex;
    -webkit-box-align : center;
    -webkit-align-items : center;
       -moz-box-align : center;
    -ms-flex-align : center;
            align-items : center;
    -webkit-box-pack : center;
    -webkit-justify-content : center;
       -moz-box-pack : center;
    -ms-flex-pack : center;
            justify-content : center;
    width : auto;
    padding-left : 0;
  }
  .form-inline .form-check-input {
    position : relative;
    margin-top : 0;
    margin-right : 0.25rem;
    margin-left : 0;
  }
  .form-inline .custom-control {
    -webkit-box-align : center;
    -webkit-align-items : center;
       -moz-box-align : center;
    -ms-flex-align : center;
            align-items : center;
    -webkit-box-pack : center;
    -webkit-justify-content : center;
       -moz-box-pack : center;
    -ms-flex-pack : center;
            justify-content : center;
  }
  .form-inline .custom-control-label {
    margin-bottom : 0;
  }
}


.card {
  border : 0;
  margin : 15px 0;
  -webkit-box-shadow : 0 6px 0 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
          box-shadow : 0 6px 0 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
  border-radius : 8px;
}

.card .card-header {
  padding : 1.5rem;
  border-bottom : none;
  background-color : transparent;
}
.card .card-header .card-title-wrap {
  margin-left : -1.5rem;
  padding-left : 1.5rem;
  border-left : 3px solid #0BB197;
}
.card .card-subtitle {
  margin-bottom : 10px;
}
.card .card-body {
  padding : 0;
  height : auto;
}
.card .card-block {
  padding : 1rem 1.5rem 1.5rem;
}
.card .card-block:hover
{
  
  transition: transform 250ms ease-in-out;
}
.card .card-bordered {
  border : 1px solid rgba(0, 0, 0, 0.06);
}
.card.card-transparent {
  background-color : transparent !important;
}
.card .card {
  -webkit-box-shadow : none;
          box-shadow : none;
}
.card .card-img {
  position : relative;
}
.card .card-img .card-title {
  color : #FFFFFF;
  position : absolute;
  bottom : 0;
  left : 0;
  max-width : 100%;
  padding : 24px;
}
.card .card-img.overlap {
  margin-top : -40px;
}
.card .btn-floating {
  display : inline-block;
  color : #FFFFFF;
  position : relative;
  overflow : hidden;
  z-index : 1;
  width : 40px;
  height : 40px;
  line-height : 40px;
  padding : 0;
  background-color : #8C43D5;
  border-radius : 50%;
  -webkit-transition : 0.3s;
       -o-transition : 0.3s;
     -moz-transition : 0.3s;
          transition : 0.3s;
  cursor : pointer;
  vertical-align : middle;
  font-size : 1rem;
  -webkit-box-shadow : 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow : 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.card .btn-floating.btn, .card .fc button.btn-floating, .fc .card button.btn-floating {
  margin : 0;
}
.card .btn-floating i {
  width : inherit;
  display : inline-block;
  text-align : center;
  color : #FFFFFF;
  font-size : 2rem;
  line-height : 40px;
}
.card .btn-floating.btn-large {
  width : 56px;
  height : 56px;
}
.card .btn-floating.btn-large.halfway-fab {
  bottom : -28px;
}
.card .btn-floating.btn-large i {
  line-height : 56px;
}
.card .btn-floating.halfway-fab {
  position : absolute;
  right : 24px;
  bottom : -20px;
}
.card .card-reveal {
  padding : 24px;
  position : absolute;
  background-color : #FFFFFF;
  width : 100%;
  overflow-y : auto;
  left : 0;
  top : 100%;
  height : 100%;
  z-index : 3;
  -webkit-transition : height 1s ease-in-out;
       -o-transition : height 1s ease-in-out;
     -moz-transition : height 1s ease-in-out;
          transition : height 1s ease-in-out;
  display : none;
}
.card .card-reveal .card-title {
  cursor : pointer;
}
.card.horizontal .card-body {
  display : -webkit-flex;
  display : -ms-flexbox;
  display : -webkit-box;
  display :    -moz-box;
  display :         flex;
}
.card.horizontal .card-img {
  max-width : 50%;
}
.card.horizontal .card-img img {
  border-radius : 2px 0 0 2px;
  max-width : 100%;
  width : auto;
}
.card .card-stacked {
  display : -webkit-flex;
  display : -ms-flexbox;
  display : -webkit-box;
  display :    -moz-box;
  display :         flex;
  -webkit-flex-direction : column;
      -ms-flex-direction : column;
  -webkit-box-orient : vertical;
  -webkit-box-direction : normal;
     -moz-box-orient : vertical;
     -moz-box-direction : normal;
          flex-direction : column;
  -webkit-flex : 1;
      -ms-flex : 1;
  -webkit-box-flex : 1;
     -moz-box-flex : 1;
          flex : 1;
  position : relative;
}

.w10
{
  width:100px;
}

.w8
{
  width:80px;
}

.form-control
{
  border:solid 1px silver !important;
}

.wd-100
{
  width:100px;
}
select.form-control { -webkit-appearance: menulist; }

.viewbtn
{

  font-size: 20px;
}
.wd-50
{
  min-width:50px;
}

.custom-editor .editorClassName {
  border: 1px solid #ced4da; /* Adjust the color and width as needed */
  border-radius: 4px; /* Optional: adds rounded corners */
  padding: 10px; /* Optional: adds padding inside the editor */
}

.custom-editor .toolbarClassName {
  border-bottom: 1px solid #ced4da; /* Border for the toolbar */
}
